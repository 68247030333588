/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import Layout from "../components/Layout";
import RichTextDisplay from "../components/RichTextDisplay";
import SEO from "../components/SEO";

const PortfolioItemTemplate = ({ data }) => {
  const { title, company, images, description, body } = data.portfolioItem;
  const summary = description || {};
  const [mainImage, ...itemImages] = images;

  return (
    <Layout>
      <PortfolioItem fade to="/projects">
        <SEO title={title} summary={summary} />
        <HeaderStructureWrapper>
          <h1>{company}</h1>
          <h2>{title}</h2>
          <p>{description.text}</p>
        </HeaderStructureWrapper>
        <ImageWrapper>
          <Image fluid={mainImage.fluid} alt={title} />
        </ImageWrapper>
        <Body>
          <RichTextDisplay json={JSON.parse(body.body)} />
        </Body>
        <AniLink
          fade
          to="/projects"
          className="btn-primary"
          style={{ marginTop: "3rem" }}
        >
          Back to Projects
        </AniLink>
      </PortfolioItem>
    </Layout>
  );
};

const PortfolioItem = styled.div`
  border-radius: 8px;
  text-align: center;
  font-size: 1.1rem;
  color: #1e2c33;
  padding-bottom: 80px;
  p {
    text-align: left;
  }
`;

const HeaderStructureWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 680px;
  padding: 5rem 1rem;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 1120px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  box-shadow: 0 4px 18px rgba(130, 144, 151, 0.2);
  border-radius: 8px;
  img {
    max-width: 100%;
    display: block;
    border: 1px solid rgba(222, 226, 230, 0.5);
    border-radius: 8px;
  }
`;

const Body = styled.div`
  max-width: 680px;
  padding: 2rem 1rem 6rem 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  img {
  }
  h1,
  h2 {
    padding-top: 2rem;
  }
`;

export const getPortfolioItem = graphql`
  query getPortfolioItem($id: String!) {
    portfolioItem: contentfulPortfolioItem(contentful_id: { eq: $id }) {
      category {
        slug
      }
      title
      company
      body {
        body
      }
      images {
        fluid(quality: 90, maxWidth: 1120) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        text: description
      }
    }
  }
`;

export default PortfolioItemTemplate;
